import MailPage from 'feedback_main_page';

const routesPath = {

    feedback_page_root: { path: '/' },
    feedback_page: { path: '/feedback', component: MailPage }


}

export default routesPath;