import React from 'react';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import routesPath from 'config/routes-config'


function App() {

    return (
        <Router>
            <Switch>
                <Route exact path={routesPath.feedback_page_root.path} render={(props) => {
                    return <Redirect to={routesPath.feedback_page.path} />
                }} />
                <Route path={routesPath.feedback_page.path} component={routesPath.feedback_page.component} />
            </Switch>
        </Router>
    );
}

export default App
