import { isMobile } from 'react-device-detect';
import autodesk_logo from './assets/img/autodesk-nav-logo.svg';
import './index.css';

function MailPage() {

  var link = 'https://manage.autodesk.com/insights-recommendation/personal-insights'

  return (
    <div>
      <header className="mail-header">
        <img src={autodesk_logo} alt="autodesk_nav_logo"></img>
      </header>
      <div className="main-content">
        <h1 id="main-title">Thank you for your feedback!</h1>
        <p className="main-text">We will use this feedback to improve what we suggest {isMobile ? "for you." : []} </p>
        {!isMobile ? <p className="main-text">for you.</p> : []}
        <br />
        <a id="insight-page-redirect" href={link} rel="noreferrer" target='_blank'>Give more feedback in Autodesk Account</a>
      </div>
      <br />
    </div>
  );
}

export default MailPage;
